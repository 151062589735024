import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import GetStarted from "../components/sections/GetStarted";

import hero from "../images/apple-pay/apple-pay-hero.png";
import section1 from "../images/apple-pay/Illustration-apple-pay-1.png";
import section2 from "../images/apple-pay/Illustration-apple-pay-2.png";
import card1 from "../images/apple-pay/section-img-1.png";
import card2 from "../images/apple-pay/section-img-2.png";
import {Link} from "gatsby";

const ApplePay = () => {
    return (
        <Layout hiddenContactBtn>
            <SEO
                title="Cards - ApplePay - Digimeth.net"
                description="Make the payment flow pleasant with PSP Digimeth. Integrate ApplePay and allow your customers to pay in one click."
            />
            <article>
                <section
                    className="typical__padding"
                    style={{paddingTop: `40px`, paddingBottom: `120px`}}
                >
                    <div className="site__container grid" style={{justifyContent: 'space-between'}}>
                        <div className="left">
                            <div className="content--wrapper">
                                <div className="content" style={{maxWidth: `600px`}}>
                                    <h2 style={{marginBottom: `20px`}}>
                    <span className="default">
                      Enjoy simple and secure payments with
                    </span>
                                        <span> ApplePay</span>
                                    </h2>
                                    <h3 className="description">
                                        ApplePay™ is an instant payment method from Apple. It enables users to pay in
                                        one click. Moreover the card data is securely stored by Apple.
                                    </h3>
                                    <br/>
                                    <small
                                        style={{
                                            maxWidth: `530px`,
                                            width: `100%`,
                                            display: `inline-block`,
                                        }}
                                    >
                                        Enjoy ApplePay payment experience in different web browsers or with any Apple device
                                    </small>
                                </div>
                            </div>
                        </div>
                        <div className="right">
                            <picture className="fluid__wrapper">
                                <img alt="hero" src={hero}/>
                            </picture>
                        </div>
                    </div>
                </section>
                <section className="alternative_payment-section typical__padding co-bg--white">
                    <div className="site__container">
                        <div className="customer-container">
                            <div className="customer-container-card">
                                <div className="content" style={{ flexDirection: 'row-reverse', display: 'flex' }}>
                                    <div className="side left">
                                        <div className="customer-cover" style={{justifyContent: 'flex-end', paddingRight: '0'}}>
                                            <div className="content-wrapper-box">
                                                <p>How does it work?</p>
                                                <h3>
                                                    Customer either already has or adds a credit or debit
                                                    card to the Apple Wallet™ app.
                                                </h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="side right">
                                        <div className="customer-cover" style={{justifyContent: 'flex-start'}}>
                                            <div className="content-wrapper-box">
                                                <picture>
                                                    <img src={section1} alt="connect"/>
                                                </picture>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="customer-container-card">
                                <div className="content" style={{ flexDirection: 'row'}}>
                                    <div className="side left">
                                        <div className="customer-cover" style={{justifyContent: 'flex-start', paddingLeft: '0'}}>
                                            <div className="content-wrapper-box">
                                                <h3>
                                                    Сustomer taps the ApplePay button and makes a
                                                    transaction.
                                                </h3>
                                                <p>
                                                    That’s it – no need to fill out forms or enter payment
                                                    info!
                                                </p>
                                                <Link
                                                    to="/contact"
                                                    style={{marginTop: `20px`, marginBottom: `20px`, display: `inline-block`}}
                                                >
                                                    <button
                                                        type="button"
                                                        className="digimeth-btn default-btn xl"
                                                    >
                                                        Start integration
                                                    </button>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="side right">
                                        <div className="customer-cover" style={{justifyContent: 'flex-end'}}>
                                            <div className="content-wrapper-box">
                                                <picture>
                                                    <img src={section2} alt="connect"/>
                                                </picture>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="secondary_payment-section typical__padding co-bg--grey mt-100">
                    <div className="site__container " style={{paddingTop: `80px`}}>
                        <h2 className="secodary-title">
                            How to integrate ApplePay to your website
                        </h2>
                        <p
                            className="secondary-description"
                            style={{maxWidth: `530px`, width: `100%`, margin: `0 auto`}}
                        >
                            ApplePay can be integrated directly via ApplePay API or
                            through the Digimeth payment page
                        </p>
                        <div className="connection-container">
                            <div className="connection-container-card">
                                <div className="content">
                                    <div className="top">
                                        <h3 className="title">Connection with ApplePay API</h3>
                                        <p className="description">
                                            You can place the ApplePay button directly on your
                                            website. Your website should use a HTTPS connection and
                                            you need to perform additional steps to
                                            get started with the ApplePay API. Please find more
                                            information and the instructions via the
                                            <a
                                                href="https://developer.apple.com/documentation/apple_pay_on_the_web"
                                                target="_blank"
                                                style={{textDecoration: `none`, color: `#437873`}}
                                                rel="noreferrer"
                                            >
                                                {" "}
                                                link
                                            </a>
                                            .
                                        </p>
                                    </div>
                                    <picture>
                                        <img src={card1} alt="connection"/>
                                    </picture>
                                </div>
                            </div>
                            <div className="connection-container-card">
                                <div className="content">
                                    <div className="top">
                                        <h3 className="title">
                                            Connection via the Digimeth payment page
                                        </h3>
                                        <p className="description">
                                            All you need is an integration with Digimeth - there is no
                                            need for additional integrations. The ApplePay button
                                            will be on the Digimeth payment page. The technical
                                            integration is also the same as for other payment methods
                                            used through the payment page
                                        </p>
                                    </div>
                                    <picture>
                                        <img src={card2} alt="connection"/>
                                    </picture>
                                </div>
                            </div>
                        </div>
                        <div className="useful-links-containder">
                            <h3 className="useful-links-title">Useful links</h3>
                            <ul className="useful-links-list">
                                <li>
                                    <a
                                        href="https://developer.apple.com/apple-pay/marketing"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        Brand guidelines for websites
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="https://developer.apple.com/apple-pay/acceptable-use-guidelines-for-websites/"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        ApplePay and Wallet APIs Acceptable Use Policy
                                    </a>
                                </li>
                            </ul>
                            <p>ApplePay and Apple Wallet are trademarks of Apple Inc.</p>
                        </div>
                    </div>
                </section>
                <GetStarted
                    isGetStarted={true}
                    title="Simple and secure payments with ApplePay"
                />
            </article>
        </Layout>
    );
};

export default ApplePay;
